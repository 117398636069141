const Constants = {
    apiWebService:{
      baseUrl: process.env.REACT_APP_BASEURL
    },
    backoffice: {
      url: process.env.REACT_APP_BACKOFFICE
    },
    frontend: {
      url: process.env.REACT_APP_FRONTEND
    },
    sitoWP: {
      url: process.env.REACT_APP_SITOWP
    },
    algolia: {
      appId: process.env.REACT_APP_ALGOLIA_ID,
      apiKey: process.env.REACT_APP_ALGOLIA_KEY,
      Servizi: 'Servizi',
    },
    googleMaps: {
      apiKey: process.env.REACT_APP_GOOGLEMAPS_KEY
    },
    domain: {
      url: process.env.REACT_APP_DOMAIN
    },
    azure: {
      urlImgProfilo: process.env.REACT_APP_AZURE_CONTAINER
    }
  };
  
  export default Constants;