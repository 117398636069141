import { useEffect, useState } from "react";
import Auth from "../api/Auth";
import LoadingView from "../components/generics/LoadingView";

function Verify() {
    const [loading, setLoading] = useState(true);
    const [verified, setVerified] = useState(false);

    const verify = async (id: string | null) => {
        if(id !== null)
            await Auth.Verify(id);  

        setVerified(true);
        setLoading(false);
        //let back = "/login";
        //window.location.replace(back);
    }

    useEffect(() => {
        if(!verified) {
            const queryParameters = new URLSearchParams(window.location.search);
            const id = queryParameters.get("id")
            verify(id);
        }
    });

    return ( 
        <div className="page-template">
            <div className="main-content">
                {verified &&
                    <div>
                        <h2>Grazie, la tua email è stata verificata!</h2>
                        <p>Abbiamo preso in carico la tua richiesta di unirti alla nostra piattaforma.</p>
                        <p>Attendi la conferma per poter accedere con le tue credenziali.</p>
                    </div>
                }       
            </div>
            <LoadingView visible={loading} />
        </div>
    );
}

export default Verify;