import '../../style/styleComponents/BarraRicerca.css';
import { Autocomplete, InputAdornment, Paper, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Servizio } from "../../models/Servizio";
import ApiServizi from '../../api/Servizi';
import Constants from '../../utils/Constants';
import AutocompleteGoogle from "react-google-autocomplete";
import { AddressGoogle } from '../../models/AddressGoogle';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';


interface ComponentProps {
    servizioSelezionato?: Servizio;
    onChangeServizio(servizioSelezionato?: Servizio): void; 
    indirizzoSelezionato?: string;
    onChangeIndirizzo(indirizzoSelezionato?: AddressGoogle): void; 
}
export type Props = ComponentProps;


function BarraRicerca (props: Props) {
    const [elencoServizi, setElencoServizi] = useState<Servizio[]>([]);
    const [deleteVisible, setDeleteVisible] = useState(false);

    useEffect(() => {
        if(!elencoServizi || elencoServizi.length == 0) {
            getElencoServizi(); 
        }
      }, [elencoServizi]);


    const getElencoServizi = async () => {
        const elenco = await ApiServizi.getElencoServiziAttivi();
        setElencoServizi(elenco.docs);
    }


    const handleElencoServizi = (value: Servizio | null) => {
        if (value && value._id && value.nome) {
            props.onChangeServizio(value);
        }        
    }


    return (
        <>
            <div className="input-servizio">
                <Autocomplete
                    id="combo-box-demo"
                    options={ elencoServizi }
                    value={ props.servizioSelezionato || null } //NB: senza "|| null", autocomplete risulta uncontrolled e non si autopopola!!!
                    getOptionLabel={ (option) => option.nome || ""}
                    isOptionEqualToValue={(option, value) => option._id === value._id}
                    onChange={(event, value) => handleElencoServizi(value)} 
                    className="autocomplete-servizi-mui"
                    renderInput={(params) => (
                        <div>
                          <TextField
                            {...params}
                            placeholder='Scegli il servizio'
                            InputLabelProps={{ shrink: false }}
                            variant="outlined"
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon/>
                                </InputAdornment>
                              )
                            }}
                            fullWidth
                          />
                        </div>
                    )}
                    sx={{
                        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                            border: "1px solid transparent !important",
                            width: 300
                        }
                    }}
                    PaperComponent={(props) => (
                      <Paper {...props} style={{ /*width: '96%', position:"absolute", right:"0"*/ }}>
                        {props.children}
                      </Paper>
                    )}
                  />
              </div>

            <div className="input-indirizzo" style={{display: 'flex'}}>
                  <AutocompleteGoogle
                    options={{
                      types: ["geocode", "establishment"],
                      componentRestrictions: { country: "it" },
                    }}
                    onMouseEnter={() => setDeleteVisible(true)}
                    onMouseLeave={() => setDeleteVisible(false)}
                    defaultValue={props.indirizzoSelezionato ? props.indirizzoSelezionato : ""}
                    apiKey={Constants.googleMaps.apiKey}
                    onPlaceSelected={(place) => {
                      props.onChangeIndirizzo(place)
                    }}
                    style={{ outline: "none", borderColor: "transparent" }}
                  />
                  <ClearIcon onClick={() => props.onChangeIndirizzo(undefined)} 
                    onMouseEnter={() => setDeleteVisible(true)}
                    onMouseLeave={() => setDeleteVisible(false)}
                    style={{display: deleteVisible ? 'block' : 'none'}} />
            </div>
        </>
    );
}
export default BarraRicerca;