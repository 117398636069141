import '../style/stylePages/Home.css';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import ApiServizi from '../api/Servizi';

import ApiInfermieri from '../api/Infermieri';
import DialogCalendarioInfermiere from '../components/home/DialogCalendarioInfermiere';
import Constants from '../utils/Constants';
import { AddressGoogle } from '../models/AddressGoogle';
import LoadingView from '../components/generics/LoadingView';
import { Cart } from '../models/Cart';
import { InfermiereInfo } from '../models/InfermiereInfo';
import MessageModal from '../components/generics/MessageModal';
import { useHistoryState } from '../utils/useHistoryState';
import SidebarHome from '../components/home/SidebarHome';
import React from 'react';
import { Struttura } from '../models/Struttura';
import dayjs, { Dayjs } from 'dayjs';
import { Servizio } from '../models/Servizio';
import LavoraDate from '../utils/LavoraDate';
import BarraRicerca from '../components/home/BarraRicerca';
import GifRicerca from '../components/generics/GifRicerca';


function Home() {
  const [loading, setLoading] = useState(false);
  const [showSecondText, setShowSecondText] = useState(false);
  
  const [openMessageDialog, setOpenMessageDialog] = useState(false);
  const [textMessageDialog, setTextMessageDialog] = useState("");

  const [rangePrezzo, setRangePrezzo] = React.useState<number[]>([0,999]); 
  const [dataFiltro, setDataFiltro] = React.useState<Dayjs>();
  const [strutturaSelezionata, setStrutturaSelezionata] = useState<Struttura>({});

  const [ordinamento, setOrdinamento] = useState<String>("");
  
  const [tipologiaServizio, setTipologiaServizio] = useState(0);  //0 = ... e 1 = ...
  const [servizioSelezionato, setServizioSelezionato] = useHistoryState<Servizio>("servizio", {});
  //const [indirizzoSelezionato, setIndirizzoSelezionato] = useHistoryState<AddressGoogle>("indirizzo", {});
  const [resultVuoto, setResultVuoto] = useState(false);
  const [elencoInfermieri, setElencoInfermieri] = useState<InfermiereInfo[]>();  
  const [openDialogcalendar, setOpenDialogcalendar] = useState(false);    //Popup con calendario
  var cartTemp = useRef<Cart>({}); 
  const [cart, setCart] = useHistoryState<Cart>("cart", {});
  const [infermiere, setInfermiere] = useState<InfermiereInfo>();

  const [mostraFiltriMobile, setMostraFiltriMobile] = useState(false);

  const [numInfermieriDaMostrare, setNumInfermieriDaMostrare] = useState(10);
  const [numInfermieriDaCaricare, setNumInfermieriDaCaricare] = useState(10);   //Solo deciso qui il valore

  const [descrizioneAperta, setDescrizioneAperta] = useState<string | null>(null);


  const cambiaServizioSelezionato = (servizioSelezionato: Servizio) => {
    setServizioSelezionato(servizioSelezionato);
    const idServizio = servizioSelezionato._id;
    const nomeServizio = servizioSelezionato.nome;
    var newCartTemp = Object.assign({}, cartTemp.current);
    newCartTemp.idServizio = idServizio;
    newCartTemp.nomeServizio = nomeServizio;
    cartTemp.current = newCartTemp;
  }

  const getElencoInfermieriByCerca = async () => {
    setLoading(true);
    setElencoInfermieri(undefined);
    setTimeout(() => {
      if(!elencoInfermieri)
        setShowSecondText(true);
    }, 5000);

    setTipologiaServizio(0);

    setCart(cartTemp.current);
    let data : InfermiereInfo[] = [];
    
    if (cartTemp.current && cartTemp.current.idServizio && cartTemp.current.indirizzo) {
      var prezzoMin = "";
      if (rangePrezzo && rangePrezzo[0]) {
        prezzoMin = rangePrezzo[0].toString();
      }
      var prezzoMax = "";
      if (rangePrezzo && rangePrezzo[1]) {
        prezzoMax = rangePrezzo[1].toString();
      }
 
      data = await ApiInfermieri.getInfermiereByFilter(cartTemp.current.idServizio, cartTemp.current.indirizzo, dataFiltro, prezzoMin, prezzoMax, strutturaSelezionata?._id);
      if (!data) {
        data = [];
        setElencoInfermieri(data);
        setResultVuoto(true);
        setTextMessageDialog("Ci dispiace. C'è stato un problema tecnico.");
        setOpenMessageDialog(true);
        setLoading(false);
        setShowSecondText(false);
        return;
      }
      if (data.length == 0 || !data.length) {
        setResultVuoto(true);
      } 
      else { setResultVuoto(false); }
    }
    else { 
      setTextMessageDialog("Riempi i campi obbligatori.");
      setOpenMessageDialog(true);
      setLoading(false);
      setShowSecondText(false);
      return;
    }
    
    if (data[0] && data[0].tipologiaServizio) {
      setTipologiaServizio(data[0].tipologiaServizio);
    }

    setElencoInfermieri(data);
    setLoading(false);
    setShowSecondText(false);
    setMostraFiltriMobile(false);
  }

  const clickSuInfermiere = async (idInfermiere: string, nome: string, cognome: string, prezzo: number, primaDisponibilita: Date, durata: number, noteAppuntamento: string, indirizzoInfermiere: string) => {
    var copiaCart = Object.assign({}, cart);
    var copiaInfermiereInfo = copiaCart.infermiereInfo || {};
    copiaInfermiereInfo.idUtente = idInfermiere;
    copiaInfermiereInfo.nome = nome;
    copiaInfermiereInfo.cognome = cognome;
    copiaCart.infermiereInfo = copiaInfermiereInfo;

    copiaCart.prezzoTotale = prezzo;
    copiaCart.primoOrario = primaDisponibilita;
    copiaCart.durata = durata;
    copiaCart.noteAppuntamento = noteAppuntamento;
    copiaCart.tipologiaServizio = tipologiaServizio;
    //Metto nel cart (local storage) l'indirizzo giusto
    copiaCart.indirizzo = cartTemp.current.indirizzo;
    if (tipologiaServizio && tipologiaServizio == 1) {
      copiaCart.indirizzo = indirizzoInfermiere;
    }
    

    setCart(copiaCart);   

    window.localStorage.setItem('cart', JSON.stringify(copiaCart));
    await getInfermiere(idInfermiere);
    setOpenDialogcalendar(true);
  }

  const closeDialogcalendar = () => {
    setOpenDialogcalendar(false);
    setInfermiere(undefined);
  }

  const cambiaIndirizzoSelezionato = (place: AddressGoogle) => {
    var newCartTemp = Object.assign({}, cartTemp.current);
    if(place)
      newCartTemp.indirizzo = place.formatted_address;
    else {
       newCartTemp.indirizzo = undefined;
       var textArray = document.getElementsByClassName('pac-target-input') ;
       if(textArray.length > 0) {
          var text = textArray[0] as HTMLInputElement;
          text.value = '';
       }
    }
    cartTemp.current = newCartTemp;
  }


  const getInfermiere = async (idInfermiere: string)=>{
      const data : InfermiereInfo = await ApiInfermieri.getSingoloInfermiere(idInfermiere);
      if (!data) {
        setTextMessageDialog("Ci dispiace. C'è stato un problema tecnico.");
        setOpenMessageDialog(true);
      }
      else {
        setInfermiere(data);
      }
  }


  async function getSingoloServizio (idServizioSelezionato: string| null) {
    if (idServizioSelezionato) {
      const data : Servizio = await ApiServizi.getSingoloServizio(idServizioSelezionato);
      if (!data) {
          setTextMessageDialog("Ci dispiace. C'è stato un problema tecnico.");
          setOpenMessageDialog(true);
      }
      else {
          var newCartTemp = Object.assign({}, cartTemp.current);
          newCartTemp.nomeServizio = data.nome;
          cartTemp.current = newCartTemp;
      }  
    }
  }


  useEffect(() => {
    if (!elencoInfermieri) {

      if (!cartTemp.current.idServizio && !cartTemp.current.indirizzo) {
        cartTemp.current = Object.assign({}, cart); //serve per il refresh e per il backbrowser
        setResultVuoto(true);
      }   
      if (cartTemp.current && cartTemp.current.idServizio && cartTemp.current.indirizzo) {
        getElencoInfermieriByCerca();
      }
      else { 
        //controllo che ci siano param in url (quindi se vengo da home WP): 
        const queryParams = new URLSearchParams(window.location.search);
        if (queryParams && queryParams.get("servizio") != null && queryParams.get("nomeservizio") != null && queryParams.get("indirizzo") != null) {
          var newCartTemp = Object.assign({}, cartTemp.current);
          getSingoloServizio(queryParams.get("servizio"));
          newCartTemp.idServizio = queryParams.get("servizio") || "";
          newCartTemp.nomeServizio = queryParams.get("nomeservizio") || "";
          newCartTemp.indirizzo = queryParams.get("indirizzo") || "";
          cartTemp.current = newCartTemp;
          const servizioT : Servizio = {_id: newCartTemp.idServizio, nome: newCartTemp.nomeServizio};
          cambiaServizioSelezionato( servizioT );
          getElencoInfermieriByCerca();
        } else {}
      }

    } 
    
  }, [elencoInfermieri]);

  useEffect(() => {
    if(!dataFiltro) {
      let tomorrow = dayjs().tz("Europe/Rome");
      tomorrow = tomorrow.add(1, "days");
      setDataFiltro(tomorrow)     
    }
  }, [dataFiltro]);


  const closeMessageDialog = () => {
    setOpenMessageDialog(false);
  }




  //FILTRI:
  const mostraFiltriMobileFunc = () => {
    setMostraFiltriMobile(!mostraFiltriMobile);
  }
  const onChangePrezzo = ( rangePrezzo: number[] | number ) => {
    if(rangePrezzo && Array.isArray(rangePrezzo)) {
      setRangePrezzo(rangePrezzo);
    }
  }
  const onChangedataFiltro = (newData: Dayjs) => {
    let tomorrow = dayjs().tz("Europe/Rome");
    tomorrow = tomorrow.add(1, "days");
    if ( dayjs(newData).year()) {  //Controllo che la data messa nel filtro sia valida ed esista
      setDataFiltro(newData);
    }
    else {
      setDataFiltro( tomorrow );
    }
  }
  const onChangeStruttura = (struttura: Struttura) => {
      setStrutturaSelezionata(struttura);
  }


  const primaDisponibilita = (dataPrimaDisponibilita: Date) => {
    const data = LavoraDate.lavoraDataConMese(dataPrimaDisponibilita);
    return data;
  }

  const calcolaDurataServizio = (durata: number) => {
    let durataDaMostrare = "Errore";
    if (durata > 5) {
      durataDaMostrare = LavoraDate.convertiMinutiInOraMinuti(durata);
    }
    else {
      durataDaMostrare = durata * 15 + " min";
    }
    return durataDaMostrare;
  }


  //ORDINAMENTO elencoInfermieri
  const handleOrdinamento = (event: any) => {
    setOrdinamento(event.target.value);

    if (elencoInfermieri) {
      let elencoInfermieriOrdinato = elencoInfermieri;

      switch(event.target.value) {
        case "data":
          elencoInfermieriOrdinato = elencoInfermieri.sort (({primoOrario: a}, {primoOrario: b}) => a! < b! ? -1 : a! > b! ? 1 : 0);
          break;
        case "prezzo":
          elencoInfermieriOrdinato = elencoInfermieri.sort(({prezzo:a}, {prezzo:b}) => a!-b!);
          break;
        default:
      }
      setElencoInfermieri(elencoInfermieriOrdinato);
    }

  }


  //Descrizione infermiere
  const apriChiudiDescrizione = (id: string) => {
    if (descrizioneAperta === id) {
      setDescrizioneAperta(null); 
    } else {
      setDescrizioneAperta(id);
    }
  };
  const mostraDescrizione = (description?: String, id?: string) => {
    if (description && description !== "") {
      if (descrizioneAperta && descrizioneAperta == id) {
        return description; 
      } else {
        const maxWords = 10;
        const words = description?.split(' ');
        if (words.length > maxWords) {
          return words.slice(0, maxWords).join(' ') + '...'; 
        }
        return description;
      }
    }
  };


  return (
    <div className="page-template">

      <div className="main-content">

        <div className="barra-superiore-mobile-filtri">
          <div className="bottone-filtri-mobile"><button className="btn-standard" onClick={()=>mostraFiltriMobileFunc()}>Filtri</button></div>
        </div>


        <div className="barra-superiore-ricerca">
              <BarraRicerca 
                servizioSelezionato={servizioSelezionato}
                onChangeServizio={cambiaServizioSelezionato}
                indirizzoSelezionato={cartTemp.current.indirizzo}
                onChangeIndirizzo={cambiaIndirizzoSelezionato}
              />
              <div className="contenitore-bottone-cerca">
                <button className="btn-standard" onClick={getElencoInfermieriByCerca}>Cerca</button>
              </div>
        </div>



        <div className="contenuto-esterno-pagina">
            <div className={`sidebar-left ${mostraFiltriMobile ? 'filtriShow' : 'filtriHide'}`}>
              <SidebarHome rangePrezzo={rangePrezzo} 
                            onChangePrezzo={onChangePrezzo} 
                            dataFiltro={dataFiltro}
                            onChangedataFiltro={onChangedataFiltro}
                            strutturaSelezionata={strutturaSelezionata}
                            onChangeStruttura={onChangeStruttura}
                            onCallFiltra={getElencoInfermieriByCerca}/>
            </div>

            <div className="main-column">

              <div className="riga-alta">
                <div className="">
                  <h3 className="ty-3 titolo-elenco-prof">Elenco professionisti</h3>
                </div>
                <div className="ordinamento">
                  <Box sx={{ minWidth: 20, maxWidth: 150 }} className={"campo-form"}>
                    <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Ordina per</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={ordinamento}
                        label="Ordinamento"
                        onChange={handleOrdinamento}
                      >
                        <MenuItem value={"data"}>Data</MenuItem>
                        <MenuItem value={"prezzo"}>Prezzo</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </div>
              </div>

              {resultVuoto ? (
                <div className="">
                  Spiacenti, non abbiamo trovato risultati. Prova un'altra ricerca o contattaci{" "}
                  <a href="https://futurainfermieri.it/contatti/">cliccando qui</a>.
                </div>
              ) : null}

              <div className="risultati-serp">
                {elencoInfermieri?.slice(0, numInfermieriDaMostrare).map((item, index) => (
                  <div key={index} className="riga-infermiere">
                    <div className="card dist-righe-alta">

                      <div className="colonna1-immagine">
                        <img src={item.img ? Constants.azure.urlImgProfilo + "imgprofilo/" + item.img + ".jpg" : Constants.azure.urlImgProfilo + "imgprofilo/placeholder.jpg"}></img>
                      </div>

                      <div className="colonna2">
                        <div className="nome-servizio">{item.nomeServizio ? item.nomeServizio : ""}</div>
                        {/*<div className="nome-infermiere">{item.nome} {item.cognome ? item.cognome.substring(0, 1) : ""}.</div>*/}
                        <div className="nome-infermiere">{item.nome}</div>
                        <div className="indirizzo">{tipologiaServizio == 1 ? <div>{item.indirizzo}</div> : null}</div>
                        <div className="descrizione dist-righe-bassa" onClick={() => apriChiudiDescrizione(item.idUtente!)}>
                          {descrizioneAperta === item.idUtente ? item.descrizione : mostraDescrizione(item.descrizione, item.idUtente)}
                        </div>
                      </div>

                      <div className="colonna3">
                        <div>
                          <div></div>
                          <div className="durata-div">Durata: {calcolaDurataServizio(item.durata!)}</div>
                        </div>
                        <div className="prima-disp dist-righe-bassa">
                          <div className="icona"></div>
                          <div>
                            <div className="label">Prima disponibilità</div>
                            <div className="valore">{primaDisponibilita(item.primoOrario!)}</div>
                          </div>
                        </div>
                      </div>

                      <div className="colonna4">
                        <div className="prezzo">{ item.prezzo ? item.prezzo.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ""} €</div>
                        <div><button className="btn-standard" onClick={()=>clickSuInfermiere(item.idUtente!, item.nome!, item.cognome!, item.prezzo!, item.primoOrario!, item.durata!, item.noteAppuntamento!, item.indirizzo!)}>Scegli orario e prenota</button></div>
                      </div>
       
                    </div>
                  </div>
                ))}
                {elencoInfermieri && elencoInfermieri.length > numInfermieriDaMostrare && (
                  <button onClick={() => setNumInfermieriDaMostrare((prev) => prev + numInfermieriDaCaricare)}>
                    Carica altri infermieri
                  </button>
                )}
              </div>

            </div>
        </div>
      </div>

      <div className="">
        <DialogCalendarioInfermiere open={openDialogcalendar} onClose={closeDialogcalendar} 
                                    infermiereSelezionato={infermiere} nomeServizio={cart?.nomeServizio} 
                                    primaDisponibilita={cart.primoOrario} durata={cart.durata}
                                    idServizio={cart.idServizio}
        />
      </div>

      <MessageModal open={openMessageDialog} onClose={closeMessageDialog} titolo={"Attenzione"} testo={textMessageDialog}/>

      {/*<LoadingView
        visible={loading}
      />*/}
      <GifRicerca
        showSecondText={showSecondText}
        visible={loading}
      />

    </div>
  );
}

export default Home;