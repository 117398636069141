import Axios from "axios"
import Constants from "../utils/Constants";
import Errors from "../utils/Errors";

export default class Servizi {

    static async getElencoServiziAttivi() {
        try {
            const response = await Axios.get(Constants.apiWebService.baseUrl + '/servizi/attivi');
            return response.data;
        }
        catch (err : any) {
            Errors.erroreApi(err.message);  
        }
    } 

    static async getSingoloServizio (idServizioSelezionato: string) {
        try {
            const response = await Axios.get(Constants.apiWebService.baseUrl + '/servizi/' + idServizioSelezionato);
        return response.data;
        }
        catch (err : any) {
            Errors.erroreApi(err.message);  
        }
    }

}