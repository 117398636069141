import * as React from 'react';
import CalendarioFrontend from './CalendarioFrontend';
import '../../style/styleComponents/DialogCalendarioInfermiere.css';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { InfermiereInfo } from '../../models/InfermiereInfo';
import LoadingView from '../generics/LoadingView';
import { Button } from '@mui/material';


interface ComponentProps {
    open?: boolean;
    onClose?(open:boolean): void;
    infermiereSelezionato?: InfermiereInfo;
    nomeServizio?: string;
    primaDisponibilita?: Date;
    durata?: number;
    idServizio?: string;
}
export type Props = ComponentProps;


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  
  export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
  }
  

  function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;


    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  

  function DialogcalendarioInfermiere(props: Props) {
    const [loading, setLoading] = React.useState(false);
    const [slotOrarioSelezionato, setSlotOrarioSelezionato] = React.useState<Date>();

    function onChangeOrario (slotOrario: Date) {
      setSlotOrarioSelezionato(slotOrario);
    }

    const handleClose = () => {
      setSlotOrarioSelezionato(undefined);
      props.onClose!(false);
    };


    const handleAvantiButton = () => {
      setLoading(true);
      window.location.href = '/checkout'
      setLoading(false);
    }
  
    return (
      <div>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={props.open!}
          className="dialog-calendario-infermiere"
        >

          <div className="titolo"> 
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
              <div>Scegli giorno e orario</div>
            </BootstrapDialogTitle>
          </div>

          <DialogContent dividers>
            <div className="recap">
              <div>
                <span className="label">Infermiere:</span> <span className="valore">{props.infermiereSelezionato?.nome}</span>
              </div>
              <div>
                <span className="label">Servizio:</span> <span className="valore">{props.nomeServizio}</span>
              </div>    
            </div>
            <div className="calendario">
                <CalendarioFrontend infermiereSelezionato = {props.infermiereSelezionato} 
                                    primaDisponibilita={props.primaDisponibilita} 
                                    durata={props.durata} 
                                    idServizio={props.idServizio}
                                    slotOrarioSelezionato={slotOrarioSelezionato}
                                    onChangeOrario={onChangeOrario}
                />
            </div>
          </DialogContent>
          <DialogActions>
            <button onClick={handleAvantiButton} disabled={slotOrarioSelezionato ? false : true} className='btn-standard'>Scegli orario e Prenota</button>
          </DialogActions>
        </BootstrapDialog>

        <LoadingView
                visible={loading}
        />

      </div>
    );
  }
export default DialogcalendarioInfermiere;