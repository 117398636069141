import '../../style/styleComponents/SidebarHome.css';
import { Autocomplete, Box, Button, Slider, TextField } from "@mui/material";
import { DateTimePicker, LocalizationProvider, renderTimeViewClock } from "@mui/x-date-pickers";
import 'dayjs/locale/it';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect, useState } from "react";
import ApiStrutture from '../../api/Strutture';
import { Struttura } from "../../models/Struttura";
import dayjs, { Dayjs } from "dayjs";


interface ComponentProps {
    rangePrezzo?: number[] | number;
    onChangePrezzo(p: number[] | number): void;
    dataFiltro?: Dayjs | null;
    onChangedataFiltro(p: Dayjs | null): void;
    strutturaSelezionata?: Struttura;
    onChangeStruttura(p: Struttura | null): void;
    onCallFiltra(): void;
}
export type Props = ComponentProps;



function SidebarHome(props: Props) {

    dayjs.locale('it');
    
    const [elencoStrutture, setElencoStrutture] = useState<Struttura[]>([]); 
    const [minDate, setMinDate] = useState<Dayjs>();

    const handleRangePrezzo = (event: Event, newValue: number | number[]) => {
        props.onChangePrezzo(newValue as number[]); 
    };

    const FiltraButton = () => {
        props.onCallFiltra();
    }


    const marksSlider = [
        {
          value: 0,
          label: '0€',
        },
        {
          value: 50,
          label: '50€',
        },
        {
          value: 200,
          label: '200€',
        },
        {
            value: 400,
            label: '400€',
          },
      ];


    const ElencoStrutture = async () => {
        const data = await ApiStrutture.getElencoStrutture();
        setElencoStrutture(data.docs);
    }

    const handleElencoStrutture = (value: Struttura | null) => {
        props.onChangeStruttura(value);
    };


    const changeDataFiltro = (newData: Dayjs | null) => {
        props.onChangedataFiltro(newData);
    }


    useEffect(() => {
        if (elencoStrutture && elencoStrutture.length == 0) {
            ElencoStrutture();
        }
    });

    useEffect(() => {
        if (!minDate) {
            setMinDate(props.dataFiltro || dayjs().add(1, 'day'));
        }
    })


    const tomorrow = new Date().setDate(new Date().getDate() + 1);

    return (
        <div>

            <div className="titolo-filtri">
                <h2 className="ty-3">Filtri</h2>
            </div>

            <div className="blocco-filtro-singolo">
                <h4 className="titolo-filtro-singolo">Giorno e ora</h4>
                <div className="datetime-picker">
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
                        <DateTimePicker disablePast 
                            minDate={minDate}
                            value={props.dataFiltro || null} 
                            onChange={(newValue) => changeDataFiltro(newValue)}
                            className={"campo-form"}
                            viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                            }}
                        />
                    </LocalizationProvider>
                </div>
            </div>

            <div className="blocco-filtro-singolo">
                <h4 className="titolo-filtro-singolo">Prezzo</h4>
                <Box sx={{ width: 300 }}>
                    <Slider
                        min={0}
                        max={400}
                        marks={marksSlider}
                        valueLabelDisplay="auto"
                        value={props.rangePrezzo}
                        onChange={handleRangePrezzo}
                        className={"slide-prezzo"}
                    />
                </Box>
            </div>

            <div className="blocco-filtro-singolo">
                <h4 className="titolo-filtro-singolo">Strutture</h4>
                <div className={"filtro-struttura"}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={ elencoStrutture }
                        getOptionLabel={ (option) => option.nome || ""}
                        isOptionEqualToValue={(option, value) => option._id === value._id}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} placeholder="Seleziona la struttura" />}
                        onChange={(event, value) => handleElencoStrutture(value)}
                        className={"campo-form"}
                        classes={{
                            option: 'custom-option', 
                        }}
                    />
                </div>
            </div>

            <div className="blocco-filtro-singolo">
                <button className="btn-standard" onClick={FiltraButton}>Filtra</button>
            </div>

        </div>
    );
}
export default SidebarHome;