import { TextField } from "@mui/material";
import { Prenotazione } from "../../models/Prenotazione";


interface ComponentProps {
    prenotazione?: Prenotazione;
    onChange(p: Prenotazione): void;
}
export type Props = ComponentProps;


function FormPaziente(props: Props) {

    const { prenotazione } = props;

    const onChange = (prenotazione: Prenotazione) => {

        props.onChange(prenotazione);
    }

    const handleNome = (event: React.ChangeEvent<HTMLElement>) => {
        var value = (event.target as HTMLButtonElement).value;
        var newPrenotazione = Object.assign({}, prenotazione);
        var newUtente = newPrenotazione.paziente || {};
        newUtente.nome = value;
        newPrenotazione.paziente = newUtente;
        onChange(newPrenotazione);
    }
    const handleCognome = (event: React.ChangeEvent<HTMLElement>) => {
        var value = (event.target as HTMLButtonElement).value;
        var newPrenotazione = Object.assign({}, prenotazione);
        var newUtente = newPrenotazione.paziente || {};
        newUtente.cognome = value;
        newPrenotazione.paziente = newUtente;
        onChange(newPrenotazione);
    }
    const handleEmail = (event: React.ChangeEvent<HTMLElement>) => {
        var value = (event.target as HTMLButtonElement).value;
        var newPrenotazione = Object.assign({}, prenotazione);
        var newUtente = newPrenotazione.paziente || {};
        newUtente.email = value;
        newPrenotazione.paziente = newUtente;
        onChange(newPrenotazione);
    }
    const handleTelefono = (event: React.ChangeEvent<HTMLElement>) => {
        var value = (event.target as HTMLButtonElement).value;
        var newPrenotazione = Object.assign({}, prenotazione);
        var newUtente = newPrenotazione.paziente || {};
        newUtente.telefono = value;
        newPrenotazione.paziente = newUtente;
        onChange(newPrenotazione);
    }



return (
    <div>
        <div className="riga-form">
                            <TextField
                                label={"Nome"}
                                className={"campo-form"}
                                required
                                error={prenotazione?.invio && prenotazione.paziente && !prenotazione.paziente.nome || prenotazione?.invio && !prenotazione.paziente}
                                value={prenotazione?.paziente?.nome || ""}
                                onChange={handleNome}
                            />
                            <TextField
                                label={"Cognome"}
                                className={"campo-form"}
                                required
                                error={prenotazione?.invio && prenotazione.paziente && !prenotazione.paziente.cognome || prenotazione?.invio && !prenotazione.paziente}
                                value={prenotazione?.paziente?.cognome || ""}
                                onChange={handleCognome}
                            />
                        </div>
                        <div className="riga-form">
                            <TextField
                                label={"E-mail"}
                                type={"email"}
                                className={"campo-form"}
                                error={prenotazione?.invio && prenotazione.paziente && !prenotazione.paziente.email || prenotazione?.invio && !prenotazione.paziente}
                                value={prenotazione?.paziente?.email || ""}
                                onChange={handleEmail}
                            />
                            <TextField
                                label={"Cellulare"}
                                type={"telephone"}
                                className={"campo-form"}
                                error={prenotazione?.invio && prenotazione.paziente && !prenotazione.paziente.telefono || prenotazione?.invio && !prenotazione.paziente}
                                value={prenotazione?.paziente?.telefono || ""}
                                onChange={handleTelefono}
                            />
                        </div>       
    </div>
);

}
export default FormPaziente;