import React from "react";
import { render } from "react-dom";
import MessageModal from "../components/generics/MessageModal";



export default class Errors extends React.Component {

    static erroreApi(testoErrore : string) {
        console.log("Errore call api: " + testoErrore);
    }
    
}