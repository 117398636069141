import dayjs, {Dayjs} from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc);
dayjs.extend(timezone);


export default class LavoraDate {

    //Prende formato data e restituisce stringa "12 Maggio 2023" (da mostrare a frontend)
    static lavoraDataConMese (formatoData: Date) {
        const nomiMesi = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];
        const date = dayjs(formatoData).tz("Europe/Rome");
        const anno = date.year();
        const mese = nomiMesi[date.month()];
        const giorno = date.date();
        const dataLavorata = giorno + ' ' + mese + ' ' +anno;
        return dataLavorata;
    }

    //Prende formato string ("2023-06-17T14:00:00") e restituisce stringa "17 Giugno 2023 - ore 14:00" (da mostrare a frontend)
    static lavoraDataConMeseEOrario (formatoData: string) {
        const nomiMesi = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];
        const date = dayjs(formatoData).tz("Europe/Rome");
        const anno = date.year();
        const mese = nomiMesi[date.month()];
        const giorno = date.date();
        const ora = String(date.hour()).padStart(2, "0");
        const minuti = String(date.minute()).padStart(2, "0");
        const dataLavorata = giorno + ' ' + mese + ' ' + anno + ' - ' + ora + ':' + minuti;
        return dataLavorata;
    }


    //Prende formato data e restituisce stringa "2023-7-2" (formato che vogliono alcune api)
    static lavoraData (formatoData: Date) {
        const date = new Date(formatoData);
        const anno = date.getFullYear();
        const mese = String(date.getMonth()+1).padStart(2, "0") + 1;
        const giorno = String(date.getDate()).padStart(2, "0");
        const dataLavorata = anno + '-' + mese + '-' + giorno;
        return dataLavorata;
    }

    
    //Prende formato data e restituisce stringa "2023-07-02T17:00:00" (formato che vogliono alcune api)
    static lavoraDataPerApi (data: Date) {
        const dataFormattata = data.getFullYear() + '-' + String(data.getMonth()+1).padStart(2, "0") + '-' + String(data.getDate()).padStart(2, "0") + 'T' + String(data.getHours()).padStart(2, "0") + ':' + String(data.getMinutes()).padStart(2, "0") + ':' + String(data.getSeconds()).padStart(2, "0");
        return dataFormattata;
    }


    //Prende formato data e restituice stringa oraria "9:30"
    static prendeOrario (formatoData: Date) {
        const date = dayjs(formatoData).tz("Europe/Rome");
        const ora = date.hour();
        const minuti = String(date.minute()).padStart(2, "0");
        return ora + ':' + minuti;
    }


    //Converte i minuti in orario: 90 -> 1h e 30 minuti
    static convertiMinutiInOraMinuti(minutiTotali: number) {
        let ore = Math.floor(minutiTotali / 60);
        let minuti = minutiTotali % 60;           
        return `${ore} ore e ${minuti} minuti`;
    }
    

    //Prende data in stringa ("2023-06-17T14:00:00") -> Restituisce orario (14:00)
    static convertiDataInOre (data: string) {
        const dataD = new Date(data);
        const orario = dataD.toLocaleTimeString('it-IT', { hour: '2-digit', minute: '2-digit' });
        return orario;
    }


    //Prende data in stringa ("2023-06-17T14:00:00") -> Restuisce data in stringa leggibile a frontend ("Giovedì 17 Giugno 2023")
    static convertiDataInDataCompleta (data: string) {
        const dataD = new Date(data);
        const options: Intl.DateTimeFormatOptions = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
        const dataFormattata = dataD.toLocaleDateString('it-IT', options);
        return dataFormattata;
    }


}