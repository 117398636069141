import { Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, TextField } from '@mui/material';
import React, { useState } from 'react';
import '../../style/styleComponents/auth/LoginForm.css';
import PasswordField from '../generics/PasswordField';

interface LoginProps {
  onSend: (email: string) => void;
}

const RecuperaPasswordForm: React.FC<LoginProps> = ({ onSend }) => {
  const [email, setEmail] = useState('');
  const [invioEseguito, setInvioEseguito] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSend(email);
    setInvioEseguito(true);
  };

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit} className='login-form'>

        <div className="campi-form">
          <div className="riga-form">
            <TextField
              label={"E-mail"}
              type="email"
              className={"campo-form"}
              required
              error={!email && invioEseguito}
              value={email || ""}
              onChange={e => setEmail(e.target.value)}
            />
          </div>
        </div>

        <button type="submit" className='btn-standard'>Invia</button>
      </form>
    </div>
  );
};

export default RecuperaPasswordForm;
