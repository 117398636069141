import { Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, TextField } from '@mui/material';
import React, { useState } from 'react';
import '../../style/styleComponents/auth/LoginForm.css';
import PasswordField from '../generics/PasswordField';
import Constants from '../../utils/Constants';

interface LoginProps {
  onSend: (password: string) => void;
}

const ResetPasswordForm: React.FC<LoginProps> = ({ onSend }) => {
  const [password, setPassword] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSend(password);
  };

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit} className='login-form'>

        <div className="campi-form">
            <div className="riga-form">
            <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <PasswordField
                id="outlined-password-input"
                label="Password"
                required
                onChange={setPassword} />
            </FormControl>
                
            </div>
        </div>

        <button type="submit" className='btn-standard'>Invia</button>
      </form>
    </div>
  );
};

export default ResetPasswordForm;
