import { SyntheticEvent, useEffect, useState } from "react";
import { InfermiereInfo } from "../models/InfermiereInfo";
import LavoraDate from '../utils/LavoraDate';
import { Servizio } from "../models/Servizio";
import ApiServizi from '../api/Servizi';
import ApiInfermieri from '../api/Infermieri';
import ApiPrenotazioni from '../api/Prenotazioni';
import '../style/stylePages/Checkout.css';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Radio, RadioGroup, Tab, Tabs, TextField } from "@mui/material";
import LoadingView from "../components/generics/LoadingView";
import { Cart } from "../models/Cart";
import { Prenotazione } from "../models/Prenotazione";
import MessageModal from "../components/generics/MessageModal";
import { useHistoryState } from "../utils/useHistoryState";
import FormPaziente from "../components/checkout/FormPaziente";
import { useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";


function Checkout() {
    const [tabValue, setTabValue] = useState("0");

    const [openMessageDialog, setOpenMessageDialog] = useState(false);
    const [textMessageDialog, setTextMessageDialog] = useState("");
    const [loading, setLoading] = useState(false);
    const [utenteLoggato, setUtenteLoggato] = useState<InfermiereInfo>();
    const [cart, setCart] = useState<Cart>();
    const [infermiereSelezionato, setInfermiereSelezionato] = useState<InfermiereInfo>();
    const [servizioSelezionato, setServizioSelezionato] = useState<Servizio>();
    const [prenotazione, setPrenotazione] = useHistoryState<Prenotazione>("prenotazione", {});  //nome..,+bool (invio) che va a true quando clico su prenota

    const closeMessageDialog = () => {
        setOpenMessageDialog(false);
      }

    useEffect( () => {
        if (!utenteLoggato) {
            utenteLoggatoF();
        }
        if ( window.localStorage.getItem('cart') == '""' ) {
            window.location.href = '/';
        }

        if (!cart) {
            var cartLS = JSON.parse(window.localStorage.getItem('cart') || '{}' );
            setCart( cartLS !== "undefined" && cartLS !== null ? cartLS : '{}');
        }
          
        if (servizioSelezionato == undefined || servizioSelezionato == null || infermiereSelezionato == undefined || infermiereSelezionato == null) {
            if (cart && cart.idServizio && cart.infermiereInfo && cart.infermiereInfo.idUtente) {
                var newPrenotazione = Object.assign({}, prenotazione);
                newPrenotazione.idServizio = cart.idServizio;
                newPrenotazione.nomeServizio = cart.nomeServizio;
                newPrenotazione.idInfermierePrenotato = cart.infermiereInfo.idUtente;
                newPrenotazione.nomeInfermierePrenotato = `${cart.infermiereInfo.nome} ${cart.infermiereInfo.cognome}`;
                newPrenotazione.indirizzo = cart.indirizzo;
                newPrenotazione.prezzoTotale = cart.prezzoTotale;
                newPrenotazione.durata = cart.durata;
                newPrenotazione.noteAppuntamento = cart.noteAppuntamento;
                newPrenotazione.data = cart.data;
                if (!newPrenotazione.radioButtonChiPrenota) {
                    newPrenotazione.radioButtonChiPrenota = "0";
                }
                setPrenotazione(newPrenotazione);

                getSingoloServizio(cart.idServizio);
                getSingoloInfermiere(cart.infermiereInfo.idUtente);
            } else {}
        }
         
    } );



    //Vedo utente loggato (infermiere)
    const utenteLoggatoF = () => {
        let authCookie = Cookies.get('auth');
        if(authCookie) {
            let ck = JSON.parse(authCookie);
            if(ck) {
                var newUtenteLoggato = Object.assign({}, utenteLoggato);
                newUtenteLoggato.idUtente = ck.user._id;
                newUtenteLoggato.nome = ck.user.nome;
                newUtenteLoggato.cognome = ck.user.cognome;
                newUtenteLoggato.email = ck.user.email;
                newUtenteLoggato.telefono = ck.user.telefono;
                //Metto i campi del cookie dentro prenotazione:
                setUtenteLoggato(newUtenteLoggato);
                var newPrenotazione = Object.assign({}, prenotazione);
                newPrenotazione.utentePrenotante = {};
                newPrenotazione.utentePrenotante.nome = newUtenteLoggato.nome;
                newPrenotazione.utentePrenotante.cognome = newUtenteLoggato.cognome;
                newPrenotazione.utentePrenotante.email = newUtenteLoggato.email;
                if (newUtenteLoggato.telefono) {
                    newPrenotazione.utentePrenotante.telefono = newUtenteLoggato.telefono;
                }
                setPrenotazione(newPrenotazione);
            }
        }
    }
    



    async function getSingoloServizio (idServizioSelezionato: string) {
        const data : Servizio = await ApiServizi.getSingoloServizio(idServizioSelezionato);
        if (!data) {
            setTextMessageDialog("Ci dispiace. C'è stato un problema tecnico.");
            setOpenMessageDialog(true);
        }
        else {
            setServizioSelezionato(data);
        }  
    }
    async function getSingoloInfermiere (idInfermiere: string) {
        const data : InfermiereInfo = await ApiInfermieri.getSingoloInfermiere(idInfermiere);
        if (!data) {
            setTextMessageDialog("Ci dispiace. C'è stato un problema tecnico.");
            setOpenMessageDialog(true);
        }
        else {
            setInfermiereSelezionato(data);
        }  
    }

    
    const handleInviaForm = async () => {
        setLoading(true);
        var newPrenotazione = Object.assign({}, prenotazione);
        newPrenotazione.invio = true;
        setPrenotazione(newPrenotazione);


        if (prenotazione && prenotazione.radioButtonChiPrenota == "0") {
            newPrenotazione.paziente = undefined;
            setPrenotazione(newPrenotazione);
        }
        if (prenotazione && prenotazione.radioButtonChiPrenota == "1") {
            if (!newPrenotazione.paziente || !newPrenotazione.paziente.nome || !newPrenotazione.paziente.cognome) {
                setTextMessageDialog("Riempi i campi obbligatori");
                setOpenMessageDialog(true);
                setLoading(false);
                return
            } else {}
        }   


        if (prenotazione && prenotazione.utentePrenotante && prenotazione.utentePrenotante.email && prenotazione.utentePrenotante.nome && prenotazione.utentePrenotante.cognome && prenotazione.utentePrenotante.telefono && prenotazione.checkboxPrivacy) {
               
            const data = await ApiPrenotazioni.inviaPrenotazione(newPrenotazione);
            if (!data) {
                setTextMessageDialog("Ci dispiace. C'è stato un problema tecnico.");
                setOpenMessageDialog(true);
            } else {
                setPrenotazione({});
                window.location.href = '/thankyou-page';
            }
        } else {
            setTextMessageDialog("Riempi i campi obbligatori");
            setOpenMessageDialog(true);
        }
        setLoading(false);
    }


    const handleEmail = (event: React.ChangeEvent<HTMLElement>) => {
        var value = (event.target as HTMLButtonElement).value;
        var newPrenotazione = Object.assign({}, prenotazione);
        var newUtente = newPrenotazione.utentePrenotante || {};
        newUtente.email = value;
        newPrenotazione.utentePrenotante = newUtente;
        setPrenotazione(newPrenotazione);
    }
    const handleNome = (event: React.ChangeEvent<HTMLElement>) => {
        var value = (event.target as HTMLButtonElement).value;
        var newPrenotazione = Object.assign({}, prenotazione);
        var newUtente = newPrenotazione.utentePrenotante || {};
        newUtente.nome = value;
        newPrenotazione.utentePrenotante = newUtente;
        setPrenotazione(newPrenotazione);
    }
    const handleCognome = (event: React.ChangeEvent<HTMLElement>) => {
        var value = (event.target as HTMLButtonElement).value;
        var newPrenotazione = Object.assign({}, prenotazione);
        var newUtente = newPrenotazione.utentePrenotante || {};
        newUtente.cognome = value;
        newPrenotazione.utentePrenotante = newUtente;
        setPrenotazione(newPrenotazione);
    }
    const handleTelefono = (event: React.ChangeEvent<HTMLElement>) => {
        var value = (event.target as HTMLButtonElement).value;
        var newPrenotazione = Object.assign({}, prenotazione);
        var newUtente = newPrenotazione.utentePrenotante || {};
        newUtente.telefono = value;
        newPrenotazione.utentePrenotante = newUtente;
        setPrenotazione(newPrenotazione);
    }
    const handleNote = (event: React.ChangeEvent<HTMLElement>) => {
        var value = (event.target as HTMLButtonElement).value;
        var newPrenotazione = Object.assign({}, prenotazione);
        newPrenotazione.note = value;
        setPrenotazione(newPrenotazione);
    }
    const handleCheckboxCommerciale = (event: SyntheticEvent<Element, Event>) => {
        var value = (event.target as HTMLButtonElement).value;
        if(value == "true") {
            var newPrenotazione = Object.assign({}, prenotazione);
            newPrenotazione.checkboxCommerciale = false;
            setPrenotazione(newPrenotazione);
        } else {
            var newPrenotazione = Object.assign({}, prenotazione);
            newPrenotazione.checkboxCommerciale = true;
            setPrenotazione(newPrenotazione);
        }

    }
    const handleCheckboxPrivacy = (event: SyntheticEvent<Element, Event>) => {
        var value = (event.target as HTMLButtonElement).value;
        if(value == "true") {
            var newPrenotazione = Object.assign({}, prenotazione);
            newPrenotazione.checkboxPrivacy = false;
            setPrenotazione(newPrenotazione);
        } else {
            var newPrenotazione = Object.assign({}, prenotazione);
            newPrenotazione.checkboxPrivacy = true;
            setPrenotazione(newPrenotazione);
        }
    }


    const AggiornaFormPaziente = (prenotazione: Prenotazione) => {
        var newPrenotazione = Object.assign({}, prenotazione);
        setPrenotazione(newPrenotazione);
    }


    //Per il pulsante Torna indietro (go back)
    const navigate = useNavigate();

    //TABS
    const handleTabChange = (event: any, newValue: any) => {
        setTabValue(newValue);
        if (newValue == "0") {
            var newPrenotazione = Object.assign({}, prenotazione);
            newPrenotazione.radioButtonChiPrenota = "0";
            setPrenotazione(newPrenotazione);
        }
        else {
            var newPrenotazione = Object.assign({}, prenotazione);
            newPrenotazione.radioButtonChiPrenota = "1";
            setPrenotazione(newPrenotazione);
        }
    }


    return (
        <div className="page-template page-checkout">
            <div className="main-content">
                <h1 className="titolo-pagina">Concludi la Prenotazione</h1>
                <div className="box-button-back"><button className="button-back" onClick={() => navigate(-1)}>{"< Torna indietro"}</button></div>

                <div className="contenuto-esterno-pagina">

                    <div className="main-column">
                        <div className="form">
                            <div className="">
                                <div className="">
                                    <div className="titolo-colonna margin-bottom">Per chi stai prenotando?</div>
                                </div>
                                <div className="riga-form tabs">
                                    <Tabs
                                        value={tabValue}
                                        onChange={handleTabChange}
                                        aria-label="Chi prenota"
                                        TabIndicatorProps={{
                                            style: {
                                            backgroundColor: "transparent" //è la riga sotto no il backgorund
                                            }
                                        }}
                                    >
                                        <Tab label="Per me stesso/a" value="0" className="custom-tab"/>
                                        <Tab label="Per altra persona" value="1" className="custom-tab"/>
                                    </Tabs>
                                </div>

                            </div>


                            <div className="riga-form">
                                    <div className="titolo-colonna">I tuoi dati</div>
                            </div>


                            <div className="riga-form">
                                <TextField
                                    label={"Nome"}
                                    className={"campo-form"}
                                    required
                                    disabled={utenteLoggato && utenteLoggato.nome ? true : false}
                                    error={prenotazione?.invio && prenotazione.utentePrenotante && !prenotazione.utentePrenotante.nome || prenotazione?.invio && !prenotazione.utentePrenotante}
                                    value={utenteLoggato ? utenteLoggato.nome : prenotazione.utentePrenotante?.nome || ""}
                                    onChange={handleNome}
                                />
                                <TextField
                                    label={"Cognome"}
                                    className={"campo-form"}
                                    required
                                    disabled={utenteLoggato && utenteLoggato.cognome ? true : false}
                                    error={prenotazione?.invio && prenotazione.utentePrenotante && !prenotazione.utentePrenotante.cognome || prenotazione?.invio && !prenotazione.utentePrenotante}
                                    value={utenteLoggato ? utenteLoggato.cognome : prenotazione.utentePrenotante?.cognome || ""}
                                    onChange={handleCognome}
                                />
                            </div>
                            <div className="riga-form">
                                <TextField
                                    label={"E-mail"}
                                    type={"email"}
                                    className={"campo-form"}
                                    required
                                    disabled={utenteLoggato && utenteLoggato.email ? true : false}
                                    error={prenotazione?.invio && prenotazione.utentePrenotante && !prenotazione.utentePrenotante.email || prenotazione?.invio && !prenotazione.utentePrenotante}
                                    value={utenteLoggato ? utenteLoggato.email : prenotazione.utentePrenotante?.email || ""}
                                    onChange={handleEmail}
                                />
                                <TextField
                                    label={"Cellulare"}
                                    type={"telephone"}
                                    className={"campo-form"}
                                    required
                                    error={prenotazione?.invio && prenotazione.utentePrenotante && !prenotazione.utentePrenotante.telefono || prenotazione?.invio && !prenotazione.utentePrenotante}
                                    value={prenotazione.utentePrenotante?.telefono || ""}
                                    onChange={handleTelefono}
                                />
                            </div>

                            <div className="">
                                {prenotazione.radioButtonChiPrenota == "1" ? (
                                <>
                                <div className="riga-form">
                                    <div className="titolo-colonna">I dati del paziente</div>
                                </div>
                                <div className="riga-form">
                                    <FormPaziente prenotazione={prenotazione} onChange={AggiornaFormPaziente} />
                                </div></>
                                ) : null}
                            </div>



                            <div className="riga-form">
                            <TextField
                                label="Note"
                                className={"campo-form"}
                                value={prenotazione.note || ""}
                                onChange={handleNote}
                                fullWidth={true}
                                autoFocus={true}
                                multiline={true}
                                minRows= {4}
                            />
                            </div>

                            <div className="riga-form checkbox-riga">
                                <FormControl component="fieldset">
                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            control={<Checkbox />}
                                            label="Desidero ricevere comunicazioni commerciali da parte di Futura Infermieri."
                                            labelPlacement="end"
                                            value={prenotazione.checkboxCommerciale}
                                            checked={prenotazione.checkboxCommerciale || false}
                                            onChange={handleCheckboxCommerciale}
                                        />
                                    </FormGroup>
                                </FormControl>
                            </div>
                            <div className="riga-form checkbox-riga">
                                <FormControl component="fieldset"
                                    required
                                    error={prenotazione && !prenotazione.checkboxPrivacy}
                                >
                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            control={<Checkbox classes={{ root: 'custom-checkbox' }} />}
                                            label={
                                                <div>
                                                    *Acconsento al trattamento dei miei dati personali relativi alla salute e al loro trasferimento allo specialista al fine di fissare l'appuntamento. &nbsp;
                                                    <a href="https://futurainfermieri.it/privacy-policy/" target="_blank">Leggi i dettagli</a>.
                                                </div>
                                            }
                                            labelPlacement="end"
                                            value={prenotazione.checkboxPrivacy}
                                            checked={prenotazione.checkboxPrivacy || false}
                                            onChange={handleCheckboxPrivacy}

                                        />
                                    </FormGroup>
                                    <FormHelperText>Selezionare la checkbox per procedere.</FormHelperText>
                                </FormControl>
                            </div>


                            
                        </div>
                    </div>

                    <div className="sidebar-right">
                        <div className="titolo-colonna margin-bottom">Riepilogo prenotazione</div>
                        <div className="box-sidebar-right">  
                            <div className="riga riga-con-linea servizio">
                                <div className="servizio">{servizioSelezionato ? servizioSelezionato.nome : null}</div>
                            </div>

                            <div className="riga">
                                <div className="indirizzo">
                                    <i className="fa-solid fa-location-dot"></i>
                                    {cart && cart.tipologiaServizio && cart.tipologiaServizio == 1 ? <div>Indirizzo studio: {cart ? cart.indirizzo : null}</div> : <div>Indirizzo: {cart ? cart.indirizzo : null}</div>}
                                </div>
                            </div>
                            
                            <div className="riga">
                                <div className="data">
                                    <i className="fa-regular fa-calendar"></i>
                                    <strong>{cart && cart.data ? LavoraDate.convertiDataInDataCompleta(cart.data) : null}</strong>
                                </div>
                                <div className="ora">
                                    <i className="fa-regular fa-clock"></i>
                                    <strong>Ore {cart && cart.data ? LavoraDate.convertiDataInOre(cart.data) : null}</strong>

                                </div>
                                <div className="durata">
                                    <i className="fa-regular fa-hourglass"></i>
                                    <strong>Durata {cart && cart.durata ? cart.durata*15 : null} minuti</strong>
                                </div>
                                <div className="infermiere">Infermiere: <strong>{infermiereSelezionato? infermiereSelezionato.nome : null}</strong></div>
                            </div>
                            {/*<div>Descrizione del servizio: {servizioSelezionato ? servizioSelezionato.descrizione : null}</div>*/}
                            <div className="riga" style={{display: cart && cart.tipologiaServizio ? 'none' : 'block'}}>
                                <div><b>Importante:</b></div>
                                <div>Stai prenotando una prestazione a domicilio! Potresti essere contattato dal professionista per eventuali variazioni relative all'orario o al materiale necessario per la prestazione.<br />
                                Fai inoltre attenzione ad aver inserito correttamente il tuo indirizzo (comprensivo di numero civico) prima di concludere la prenotazione.</div>
                            </div>
                            <div className="riga riga-con-linea">
                                <div>Note per l'appuntamento:</div>
                                <div>{prenotazione && prenotazione.noteAppuntamento ? prenotazione.noteAppuntamento : null}</div>
                            </div>

                            <div className="riga-prezzo riga">
                                <div className="label">Costo della prestazione</div> 
                                <div className="valore">{cart ? cart.prezzoTotale : null} €</div>
                            </div>
                        </div>

                        <div className="box-bottone-prenota">
                            <button onClick={handleInviaForm} className="btn-standard">Prenota</button>
                        </div>
                    </div>

                </div>
            </div>
            
            <LoadingView
                visible={loading}
            />

            <MessageModal open={openMessageDialog} onClose={closeMessageDialog} titolo={"Attenzione"} testo={textMessageDialog}/>


        </div>
    );
}

export default Checkout;