import { useEffect } from "react";
import '../style/stylePages/Thankyou.css';
import Constants from '../utils/Constants';


function ThankyouPage() { 

    useEffect( () => {
        var cartLS = JSON.parse(window.localStorage.getItem('cart') || '');
        if ( cartLS !== '""' ) {
            window.localStorage.setItem('cart', JSON.stringify(""));
        }
    } );


    return (
        <div className="Text-center-template">
            <div className="main-content thankyou-page">
                <div className="titolo-pagina">Grazie per aver prenotato!</div>
                <div><p>A breve riceverai una email con tutti i dettagli della prenotazione e i contatti del tuo infermiere. Grazie!</p></div>
                <div>Per ogni problema non esitare a contattarci al numero <strong>340 416 1119</strong></div>
                <div><a href={Constants.sitoWP.url}>Torna alla homepage</a></div>
            </div>
        </div>
    );

}


export default ThankyouPage;