import { useEffect, useState } from "react";
import LoginForm from "../components/auth/LoginForm";
import LoadingView from "../components/generics/LoadingView";
import Auth from "../api/Auth";
import Cookies from "js-cookie";
import Constants from "../utils/Constants";
import '../style/stylePages/Login.css';
import HexParser from "../utils/HexParser";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


function Login() {
    const [loading, setLoading] = useState(true);
    const [cookieChecked, setCookieChecked] = useState(false);

    const login = async (username: string, password: string, rimaniConnesso: boolean) => {
        setLoading(true);
        let daysToExpire = 1;
        if(rimaniConnesso)
            daysToExpire = 365;
        
        const minutesToExpire = daysToExpire*24*60;
        let data = await Auth.Login(username, password, minutesToExpire);
        if(data) {
            if(data.status === "KO") {
                if(data.content === "BLOCKED_USER") {
                    setLoading(false)
                    toast.error("Utente bloccato o non ancora autorizzato.");
                    return;
                } else {
                    setLoading(false)
                    toast.error("Username e/o password errati.");
                    return;
                }
            }

            Cookies.set('auth', JSON.stringify(data.content),{ expires: daysToExpire, domain: Constants.domain.url, secure: true });
            const queryParameters = new URLSearchParams(window.location.search);
            let back = queryParameters.get("callback");
            if(back)
                back = HexParser.hex_to_ascii(back);
            else
                back = Constants.frontend.url!;
            
            window.location.replace(back);
            return;
        }
        else {
            setLoading(false)
            toast.error("Username e/o password errati.");
        }
    }

    useEffect(() => {
        if(!cookieChecked) {
            let authCookie = Cookies.get('auth');
            if(authCookie) {
                let ck = JSON.parse(authCookie);
                if(ck) {
                    setCookieChecked(true);
                    const queryParameters = new URLSearchParams(window.location.search);
                    let back = queryParameters.get("callback");
                    if(back)
                        back = HexParser.hex_to_ascii(back);
                    else
                        back = Constants.frontend.url!;
                        window.location.replace(back);
                    return;
                }
            }
            setCookieChecked(true);
            setLoading(false);
        }
    })

    return (
        <>
            <div className="Text-center-template login-page">
                <div className="main-content">  
                    <h1>Login</h1>
                    <LoginForm onLogin={login}/>
                </div>
            </div>
            <LoadingView visible={loading}/>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    );
  }
  
  export default Login;