import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import TextField from '@mui/material/TextField/TextField';
import React, { useState } from 'react';
import PasswordField from '../generics/PasswordField';
import { AddressGoogle } from '../../models/AddressGoogle';
import Autocomplete from "react-google-autocomplete";
import Constants from '../../utils/Constants';
import DropdownProvincie from '../generics/DropdownProvincie';

interface LoginProps {
  onLogin: (email: string, password: string, nome: string, cognome: string, indirizzo: string, 
    ragioneSociale: string, via: string, cap: string, citta: string, provincia: string,
    codiceFiscale: string, partitaIva: string, sdi: string,
    checkboxPrivacy: boolean, checkboxDatiPersonali: boolean, checkboxCommerciale: boolean) => void;
}

const SignupForm: React.FC<LoginProps> = ({ onLogin }) => {
  const [nome, setNome] = useState('');
  const [cognome, setCognome] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [indirizzo, setIndirizzo] = useState('');

  const [ragioneSociale, setRagioneSociale] = useState('');
  const [via, setVia] = useState('');
  const [cap, setCap] = useState('');
  const [citta, setCitta] = useState('');
  const [provincia, setProvincia] = useState('');
  const [codiceFiscale, setCodiceFiscale] = useState('');
  const [partitaIva, setPartitaIva] = useState('');
  const [sdi, setSdi] = useState('');

  const [checkboxPrivacy, setCheckboxPrivacy] = useState(false);
  const [checkboxDatiPersonali, setCheckboxDatiPersonali] = useState(false);
  const [checkboxCommerciale, setCheckboxCommerciale] = useState(false);
  const [invioEseguito, setInvioEseguito] = useState(false);


  const handleCheckboxCommerciale = () => {
    setCheckboxCommerciale(!checkboxCommerciale);
  }

  const handleCheckboxPrivacy = () => {
    setCheckboxPrivacy(!checkboxPrivacy);
  };

  const handleCheckboxDatiPersonali = () => {
    setCheckboxDatiPersonali(!checkboxDatiPersonali);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onLogin(email, password, nome, cognome, indirizzo, ragioneSociale, via, cap, citta, provincia, codiceFiscale,
      partitaIva, sdi, checkboxPrivacy, checkboxDatiPersonali, checkboxCommerciale);
    setInvioEseguito(true);
  };

  const cambiaIndirizzoSelezionato = (place: AddressGoogle) => {
    if(place)
      setIndirizzo(place.formatted_address!);
  }

  return (
    <>
      <h1>Registrati</h1>

      <form onSubmit={handleSubmit} className='signup-form'>

        <div className='riga riga-2colonne'>
          <FormControl variant="outlined">
            <FormHelperText>Nome</FormHelperText>
            <TextField
              className={"campo-form"}
              required
              error={!nome && invioEseguito}
              value={nome || ""}
              onChange={e => setNome(e.target.value)}
            />
          </FormControl>

          <FormControl variant="outlined">
            <FormHelperText>Cognome</FormHelperText>
            <TextField
              className={"campo-form"}
              required
              error={!cognome && invioEseguito}
              value={cognome || ""}
              onChange={e => setCognome(e.target.value)}
            />
          </FormControl>
        </div>
        <div className='riga riga-2colonne password'>
          <FormControl variant="outlined">
            <FormHelperText>Email</FormHelperText>
            <TextField
              className={"email"}
              required
              error={!email && invioEseguito}
              value={email || ""}
              onChange={e => setEmail(e.target.value)}
            />
          </FormControl>

          <FormControl variant="outlined">
            <FormHelperText>Password</FormHelperText>
            <PasswordField
              id="outlined-password-input"
              required
              onChange={setPassword} />
          </FormControl>
        </div>

        <div className='riga indirizzo-google'>
          <FormControl className='form-field' variant="outlined">
            <FormHelperText>Indirizzo Studio</FormHelperText>
            <Autocomplete
              className='maps-autocomplete'
              options={{
                types: ["geocode", "establishment"],
                componentRestrictions: { country: "it" },
              }}
              defaultValue={indirizzo || ""}
              apiKey={Constants.googleMaps.apiKey}
              onPlaceSelected={(place) => {
                cambiaIndirizzoSelezionato(place);
              }}
            />
          </FormControl>
        </div>

        <br></br>
        <h3>Dati fatturazione</h3>

        <div className='riga riga-2colonne'>
          <FormControl variant="outlined">
            <FormHelperText>Ragione Sociale / Nome Cognome</FormHelperText>
            <TextField
              className={"campo-form"}
              required
              error={!ragioneSociale && invioEseguito}
              value={ragioneSociale || ""}
              onChange={e => setRagioneSociale(e.target.value)}
            />
          </FormControl>

          <FormControl variant="outlined">
            <FormHelperText>Indirizzo Fatturazione</FormHelperText>
            <TextField
              className={"campo-form"}
              required
              error={!via && invioEseguito}
              value={via || ""}
              onChange={e => setVia(e.target.value)}
            />
          </FormControl>
        </div>
        <div className='riga riga-2colonne'>
          <FormControl variant="outlined">
            <FormHelperText>CAP</FormHelperText>
            <TextField
              className={"campo-form"}
              required
              error={!cap && invioEseguito}
              value={cap || ""}
              onChange={e => setCap(e.target.value)}
            />
          </FormControl>

          <FormControl variant="outlined">
            <FormHelperText>Città</FormHelperText>
            <TextField
              className={"campo-form"}
              required
              error={!citta && invioEseguito}
              value={citta || ""}
              onChange={e => setCitta(e.target.value)}
            />
          </FormControl>
        </div>
        <div className='riga riga-2colonne'>
          <div className="provincia">
            <FormControl variant="outlined">
              <FormHelperText>Provincia</FormHelperText>
              <DropdownProvincie
                    className={"campo-form"}
                    provincia={provincia || "AG"} 
                    onChangeProvincia={(value) => setProvincia(value || "AG")}
                  />
            </FormControl>
          </div>

          <FormControl variant="outlined">
            <FormHelperText>Codice Fiscale</FormHelperText>
            <TextField
              className={"campo-form"}
              required
              error={!codiceFiscale && invioEseguito}
              value={codiceFiscale || ""}
              onChange={e => setCodiceFiscale(e.target.value)}
            />
          </FormControl>
        </div>

        <div className='riga riga-2colonne'>
          <FormControl variant="outlined">
            <FormHelperText>Partita IVA</FormHelperText>
            <TextField
              className={"campo-form"}
              required
              error={!partitaIva && invioEseguito}
              value={partitaIva || ""}
              onChange={e => setPartitaIva(e.target.value)}
            />
          </FormControl>

          <FormControl variant="outlined">
            <FormHelperText>SDI/PEC</FormHelperText>
            <TextField
              className={"campo-form"}
              required
              error={!sdi && invioEseguito}
              value={sdi || ""}
              onChange={e => setSdi(e.target.value)}
            />
          </FormControl>
        </div>



        <div className="riga-checkout">
          <div>
            <FormControl component="fieldset"
                          required
                          error={invioEseguito && !checkboxPrivacy}
            >
              <FormGroup aria-label="position" row>
                  <FormControlLabel
                      control={
                        <Checkbox sx={{
                          '&.Mui-checked': {
                            color: "white",
                          },
                        }}/>
                      }
                      label={
                        <div>
                            *Dichiaro di aver letto e di accettare la &nbsp;
                            <a href="https://futurainfermieri.it/privacy-policy/" target="_blank">privacy policy</a> della piattaforma.
                        </div>
                    }
                      labelPlacement="end"
                      value={checkboxPrivacy}
                      checked={checkboxPrivacy || false}
                      onChange={handleCheckboxPrivacy}
                  />
              </FormGroup>
            </FormControl>
          </div>

          <div>
            <FormControl component="fieldset"
                          required
                          error={invioEseguito && !checkboxDatiPersonali}
            >
              <FormGroup aria-label="position" row>
                <FormControlLabel
                    control={
                      <Checkbox sx={{
                        '&.Mui-checked': {
                          color: "white",
                        },
                      }}/>
                    }
                    label="*Accetto che la piattaforma utilizzi i miei dati personali."
                    labelPlacement="end"
                    value={checkboxDatiPersonali}
                    checked={checkboxDatiPersonali || false}
                    onChange={handleCheckboxDatiPersonali}
                />
              </FormGroup>
            </FormControl>
          </div>

          <div>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  control={
                    <Checkbox sx={{
                      '&.Mui-checked': {
                        color: "white",
                      },
                    }}/>
                  }
                  label="Accetto di ricevere comunicazioni di tipo commerciale."
                  labelPlacement="end"
                  value={checkboxCommerciale}
                  checked={checkboxCommerciale || false}
                  onChange={handleCheckboxCommerciale}
                />
              </FormGroup>
            </FormControl>
          </div>

        </div>


        <button type="submit" className='btn-standard'>Registrati</button>
      </form>
    </>

  );
};

export default SignupForm;
