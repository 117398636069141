
const NoPage = () => {

    return <div className="Text-center-template">
              <div className="main-content">
                <div className="titolo-pagina">404</div>
                <div>La pagina non esiste.</div>
                <div>Torna alla <a href="/">Homepage</a></div>
              </div>
            </div>;
};
  
  export default NoPage;