import { useEffect, useState } from "react";
import SignupForm from "../components/auth/SignupForm";
import LoadingView from "../components/generics/LoadingView";
import Auth from "../api/Auth";
import Cookies from "js-cookie";
import '../style/stylePages/Signup.css';
import HexParser from "../utils/HexParser";
import { ToastContainer, toast } from "react-toastify";

function Signup() {
    const [loading, setLoading] = useState(true);
    const [signupVisible, setSignupVisible] = useState(true);
    const [cookieChecked, setCookieChecked] = useState(false);

    const signin = async (email: string, password: string, nome: string, cognome: string, indirizzo: string, 
        ragioneSociale: string, via: string, cap: string, citta: string, provincia: string,
        codiceFiscale: string, partitaIva: string, sdi: string,
        checkboxPrivacy: boolean, checkboxDatiPersonali: boolean, checkboxCommerciale: boolean) => {
        setLoading(true);
            
        if (email.trim() !== '' && password.trim() !== '' && nome.trim() !== '' && cognome.trim() !== '' && ragioneSociale.trim() !== '' && via.trim() !== '' && 
            cap.trim() !== '' && citta.trim() !== '' && codiceFiscale.trim() !== '' && partitaIva.trim() !== '' && sdi.trim() !== '' && 
            checkboxPrivacy == true && checkboxDatiPersonali == true) {

            if (provincia.trim() !== '') {

                if(indirizzo && indirizzo !== '') {
                    let data = await Auth.Signup(email, password, nome, cognome, indirizzo, ragioneSociale, via, cap, citta, provincia, codiceFiscale,
                        partitaIva, sdi, checkboxPrivacy, checkboxDatiPersonali, checkboxCommerciale);
                    if(data) {
                        setSignupVisible(false);
                    }
                    else {
                        toast.error("L'utente risulta già registrato.");
                    }
                } else {
                    toast.error("Inserire un indirizzo valido per lo studio, selezionandolo dal menu a tendina");
                }

            } else {
                toast.error("Scegli una provincia nel menu a tendina del campo Provincia.");
            }

        } else {
            toast.error("Compila tutti i campi, comprese le checkbox obbligatorie.");
        }
        setLoading(false);
    }

    useEffect(() => {
        if(!cookieChecked) {
            let authCookie = Cookies.get('auth');
            if(authCookie) {
                let ck = JSON.parse(authCookie);
                if(ck) {
                    setCookieChecked(true);
                    const queryParameters = new URLSearchParams(window.location.search);
                    let back = queryParameters.get("callback");
                    if(back)
                        back = HexParser.hex_to_ascii(back);
                    else
                        back = "/";
                        window.location.replace(back);
                    return;
                }
            }
            setCookieChecked(true);
            setLoading(false);
        }
    })

    return (
        <>
            <div className="Text-center-template login-page">
                <div className="main-content thankyou-component">  
                    {signupVisible && <SignupForm onLogin={signin}/>}
                    {!signupVisible && 
                        <div>
                            <h2>Grazie per esserti registrato!</h2>
                            <p>Controlla la tua casella email. Ti abbiamo inviato un messaggio per confermare il tuo indirizzo.</p>
                            <p>Clicca sul link all'interno dell'email ricevuta e potrai accedere alla tua area riservata.</p>
                        </div>}
                </div>
            </div>
            <LoadingView visible={loading}/>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    );
  }
  
  export default Signup;