import Axios from "axios"
import Constants from "../utils/Constants";
import Errors from "../utils/Errors";
import Cookies from "js-cookie";

export default class Auth {

  static getToken = () => {
    let authCookie = Cookies.get('auth');
    if(authCookie) {
        let ck = JSON.parse(authCookie);
        if(ck)
          return ck.token;
    }
    throw new Error("Not logged in");
  }

  static async Login(username: string, password: String, expiration: number) {
    try {
        const response = await Axios.post(Constants.apiWebService.baseUrl + '/login', {
            "email": username,
            "password": password,
            "expiration": expiration
        });
            
        const token = response.data; // Access the token from the response data
        return {
          status: 'OK',
          content: token,
        }
    }
    catch (err : any) {
        //console.log(err.response.data.errors.msg);
        return {
          status: 'KO',
          content: err.response.data.errors.msg,
        }
    }
  }

  static async Signup(email: string, password: String, nome: string, cognome: string, indirizzo: string, 
    ragioneSociale: string, via: string, cap: string, citta: string, provincia: string,
    codiceFiscale: string, partitaIva: string, sdi: string,
    checkboxPrivacy?: Boolean, checkboxDatiPersonali?: Boolean, checkboxCommerciale?: Boolean) {
    try {
        const response = await Axios.post(Constants.apiWebService.baseUrl + '/register', {
            "ruolo": "infermiere",
            "autorizzatoInfermiere": true,
            "nome": nome,
            "cognome": cognome,
            "email": email,
            "password": password,
            "indirizzo": indirizzo,
            "ragioneSociale": ragioneSociale, 
            "via": via, 
            "cap": cap, 
            "citta": citta, 
            "provincia": provincia,
            "codiceFiscale": codiceFiscale, 
            "partitaIva": partitaIva, 
            "sdi": sdi,
            "checkboxPrivacy": checkboxPrivacy,
            "checkboxDatiPersonali": checkboxDatiPersonali,
            "checkboxCommerciale": checkboxCommerciale || false
        });
        const token = response.data; // Access the token from the response data
        return token;
    }
    catch (err : any) {
      Errors.erroreApi(err.message);  
    }
  }

  static async Verify(id: string) {
    try {
      await Axios.post(Constants.apiWebService.baseUrl + '/verify', {
        "id": id
    });
    } catch (err: any) {
      Errors.erroreApi(err.message);  
    }
  }

  static async ForgotPassword(email: string) {
    try {
      await Axios.post(Constants.apiWebService.baseUrl + '/forgot', {
        "email": email
      });
    } catch (err: any) {
      Errors.erroreApi(err.message);  
    }
  }

  static async ResetPassword(id: string, password: string) {
    try {
      await Axios.post(Constants.apiWebService.baseUrl + '/reset', {
        "id": id,
        "password": password
      });
    } catch (err: any) {
      Errors.erroreApi(err.message);  
    }
  }

  static async Logout() {
    try {
      const token = Auth.getToken();
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      const res = await Axios.post(Constants.apiWebService.baseUrl + '/logout', {}, config);
      return res;
    }
    catch (err : any) {
      Errors.erroreApi(err.message);  
    }  
  }
}