import Axios from "axios"
import Constants from "../utils/Constants";
import Errors from "../utils/Errors";
import dayjs, { Dayjs } from "dayjs";

export default class Infermieri {

  static async getElencoInfermieri() {
    try {
      const response = await Axios.get(Constants.apiWebService.baseUrl + '/search');
      return response.data;
    }
    catch (err : any) {
      Errors.erroreApi(err.message);  
    }
  }

  static async getSingoloInfermiere (idInfermiere: string) {
    try {
      const response = await Axios.get(Constants.apiWebService.baseUrl + '/infermieriInfo/infermiere/' + idInfermiere);
      return response.data;
    }
    catch (err : any) {
      Errors.erroreApi(err.message);  
    }
  }


  static async getInfermiereByFilter (idServizioSelezionato: string, indirizzoSelezionato: string, data: Dayjs | undefined, prezzoMin: string, prezzoMax: string, idStruttura: string | undefined) {
    var paramData = "";
    var orario = 0;
    if (data) {
      const anno = dayjs(data).year();
      const mese = dayjs(data).month() + 1;
      const giorno = dayjs(data).date();
      const dataInserita = anno + '-' + mese + '-' + giorno;
      paramData = '?data='+ dataInserita;
      orario = dayjs(data).hour();
    } else { //se data non è selezionata, metto data di oggi
      const date = new Date(Date.now());
      const anno = date.getFullYear();
      const mese = date.getMonth() + 1;
      const giorno = date.getDate();
      const dataOggi = anno + '-' + mese + '-' + giorno;
      paramData = '?data='+ dataOggi;
      orario = dayjs(date).hour();
    }

    var paramSlot = "&slot=" + orario + ":00";

    var paramPrezzoMin = "";
    if (prezzoMin && prezzoMin != "" && prezzoMin != "0") {
      paramPrezzoMin = '&prezzoMin='+ prezzoMin;
    }
    var paramPrezzoMax = "";
    if (prezzoMax && prezzoMax != "" && prezzoMax != "999") {
      paramPrezzoMax = '&prezzoMax='+ prezzoMax;
    }
    
    var paramIdStruttura = "";
    if (idStruttura) {
      paramIdStruttura = '&idStruttura=' + idStruttura;
    }

    try {
      const response = await Axios.get(Constants.apiWebService.baseUrl + '/search/' + idServizioSelezionato + '/' + btoa(indirizzoSelezionato) + paramData + paramSlot + paramPrezzoMin + paramPrezzoMax + paramIdStruttura);
      return response.data;
    }
    catch (err : any) {
      Errors.erroreApi(err.message);  
    }
  }

  
  //Giorni calendario del singolo infermiere
  //Restituisce tutti giorni di calendario dell'infermiere a partire da quella data nel param in poi
  static async getGiorniCalendarioInfermiere (idInfermiere: string, _giorno: Date) {
    //il param giornolavorato, lo prendo dalla prima disponibilità di quell'infermiere.
    const date = new Date(_giorno);
    const anno = date.getFullYear();
    const mese = date.getMonth() + 1;
    const giorno = date.getDate();
    const giornoLavorato = anno + '-' + mese + '-' + giorno;
    
    try {
      const response = await Axios.get(Constants.apiWebService.baseUrl + '/giorniCalendario/calendarioInfermiere/' + idInfermiere + '/' + giornoLavorato + '?limit=50&sort=data&order=1');
      return response.data.docs;
    }
    catch (err : any) {
      Errors.erroreApi(err.message);  
    }
  }


  //Servizio del singolo infermiere
  //Restituisce info del servizio dell'infermiere, come ad esempio giorniNo e fasciaOrariaNo
  //Restituisce model "InferiereServizio"
  static async getInfoServizioInfermiere (idInfermiere: string, idServizio: string) {
    try {
      const response = await Axios.get(Constants.apiWebService.baseUrl + '/infermieriServizi/single/' + idInfermiere + '/' + idServizio);
      return response.data;
    }
    catch (err : any) {
      Errors.erroreApi(err.message);  
    }
  }
  


}