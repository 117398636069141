import '../style/styleComponents/Footer.css';



function Footer() {

    return (
        <div className="footer-box-esterno">

        </div>
    );
}

export default Footer;