import * as React from 'react';
import '../../style/styleComponents/GifRicerca.css';

export type Props = {
    visible: boolean;
    showSecondText: boolean;
  };
  
 
  

class GifRicerca extends React.Component<Props> {
    constructor(props: Props) {
      super(props);
  
  
    }
  
    componentDidMount() {
    }
  
    render() {
      const { visible, showSecondText } = this.props;
    
  
      if (!visible) {
        return null;
      }
  
      return (
        <div className="contenitore-gifloading-ricerca">

          <div className="">

            <div className="contenitore-testo">
              {showSecondText ? (
                <p>ci siamo quasi...</p>
              ) : (
                <p>Stiamo cercando i migliori professionisti per il servizio che hai richiesto. Attendi pochi secondi...</p>
              )}
            </div>
            <div>
              <div className="contenitore-gifloading-sotto">
                <img src="img/gif-loading.gif" />
              </div>
            </div>

          </div>

        </div>
      );
    }
  }

export default GifRicerca;
