import Axios from "axios"
import Constants from "../utils/Constants";
import Errors from "../utils/Errors";

export default class Strutture { 

    static async getElencoStrutture() {
        try {
            const response = await Axios.get(Constants.apiWebService.baseUrl + '/strutture/attivi');
            return response.data;
        }
        catch (err : any) {
            Errors.erroreApi(err.message);  
        }
    } 

}