import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';


interface ComponentProps {
    open?: boolean;
    onClose?(open:boolean): void;
    titolo?: string;
    testo?: string;
}
export type Props = ComponentProps;


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  
  export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
  }
  
  function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  

  function MessageModal(props: Props) {

    const handleClose = () => {
      props.onClose!(false);
    };

  
    return (
      <div>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={props.open!}
        >
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            {props.titolo}
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>
              {props.testo}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Chiudi</Button>
          </DialogActions>
        </BootstrapDialog>


      </div>
    );
  }


export default MessageModal;