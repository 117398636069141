import Axios from "axios"
import { Prenotazione } from '../models/Prenotazione';
import Constants from "../utils/Constants";
import Errors from "../utils/Errors";

export default class Prenotazioni {

    static async inviaPrenotazione (prenotazione : Prenotazione) {
        try{
            const response =  await Axios.post(Constants.apiWebService.baseUrl + '/prenotazioni', prenotazione);
            return response;
        } 
        catch (err : any) {
            Errors.erroreApi(err.message); 
        }
    }

    static async getElencoPrenotazioni() {
        try {
            const response = await Axios.get(Constants.apiWebService.baseUrl + '/prenotazioni/all');
            return response.data;
        } 
        catch (err : any) {
            Errors.erroreApi(err.message);    
        }

    } 

    static async getSingolaPrenotazione(idPrenotazione: string) {
        try {
            const response = await Axios.get(Constants.apiWebService.baseUrl + '/prenotazioni/' + idPrenotazione);
            return response.data;
        } 
        catch (err : any) {
            Errors.erroreApi(err.message);    
        }
        
    }

}