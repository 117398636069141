import { Outlet, Link, useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import '../style/Colori-Typography.css';
import '../style/Menu.css';
import Cookies from "js-cookie";
import Constants from "../utils/Constants";
import HexParser from "../utils/HexParser";
import Footer from "../components/Footer";




const Layout = () => {

  let navigate = useNavigate(); 
  const routeChange = (path: string) =>{ 
    navigate(path);
  }

  const logout = () => {
    const callback = HexParser.ascii_to_hex(window.location.href);
    window.location.replace(`${Constants.frontend.url!}/logout?callback=${callback}`);
  }
  
  const buildLoginButton = () => {
    const callback = HexParser.ascii_to_hex(window.location.href);
    let authCookie = Cookies.get('auth');
    if(authCookie) {
        let ck = JSON.parse(authCookie);
        if(ck) {
            return (
              <div className="bottoni">
                <Link to={Constants.backoffice.url!} className="bottone">{`${ck.user.nome} ${ck.user.cognome}`}</Link>
                <button className="bottone btn-vuotoBluScuro" onClick={logout}>Esci</button>
              </div>
            )
        }
    }
    return (
      <div className="bottoni">
        <button className="bottone btn-vuotoBluScuro" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" onClick={() => routeChange(`/login?callback=${callback}`)}>
          Accedi
        </button>
        <button className="bottone btn-verde" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" onClick={() => routeChange(`/registrati?callback=${callback}`)}>Registrati</button>
      </div>
    )
  }
  return (
    <>
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
          <div className="container-fluid">
              <a className="navbar-brand" href={process.env.REACT_APP_SITOWP}><img className="logoMenu" src="img/logoApp.png" /></a>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  <button className="close-button" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">X</button>
                  

                  <div className="container-top-menu-mobile">
                    <div className="logo"><a className="navbar-brand" href={process.env.REACT_APP_SITOWP}><img className="logoMenu" src="img/logoApp.png" /></a></div>
                    {buildLoginButton()}
                  </div>
                  <div className="onda">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                      <path className="elementor-shape-fill" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
                    c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
                    c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"></path>
                    </svg>
                  </div>

                  <div className="vociMenu">
                    <li className="nav-item">
                      <a className="nav-link active" aria-current="page" href={process.env.REACT_APP_SITOWP + '/come-funziona'}>Come funziona</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link active" aria-current="page" href={process.env.REACT_APP_SITOWP + '/per-gli-infermieri'}>Per gli infermieri</a>
                    </li>

                    <li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">Info</a>
                      <ul className="dropdown-menu">
                          <li><a className="dropdown-item" href={process.env.REACT_APP_SITOWP + '/chi-siamo'}>Chi siamo</a></li>
                          <li><a className="dropdown-item" href={process.env.REACT_APP_SITOWP + '/faq'}>FAQ</a></li>
                          <li><a className="dropdown-item" href={process.env.REACT_APP_SITOWP + 'blog'}>Blog</a></li>
                          <li><a className="dropdown-item" href={process.env.REACT_APP_SITOWP + '/contatti'}>Contatti</a></li>
                      </ul>           
                    </li>

                    <li className="nav-item solo-mobile">
                      <a className="nav-link active" aria-current="page" href={process.env.REACT_APP_SITOWP + '/chi-siamo'}>Chi siamo</a>
                    </li>
                    <li className="nav-item solo-mobile">
                      <a className="nav-link active" aria-current="page" href={process.env.REACT_APP_SITOWP + '/faq'}>FAQ</a>
                    </li>
                    <li className="nav-item solo-mobile">
                      <a className="nav-link active" aria-current="page" href={process.env.REACT_APP_SITOWP + '/blog'}>Blog</a>
                    </li>
                    <li className="nav-item solo-mobile">
                      <a className="nav-link active" aria-current="page" href={process.env.REACT_APP_SITOWP + '/contatti'}>Contatti</a>
                    </li>

                    <li>
                      {buildLoginButton()}
                    </li>
                  </div>
              </ul>
              </div>
          </div>
      </nav>


      {/*Contenuto delle pagine:*/}
      <Outlet />
      <Footer/>
    </>
  )
};

export default Layout;